import { useState } from "react";
import Modal from "./Modal";


const Header = () => {

  const [modalOptions, setModalOptions] = useState({ visible: false })

  return (
    <div className="pr-8 pl-8 justify-between items-center flex w-screen pb-4 pt-8">
      <Modal onClose={() => setModalOptions({ visible: false })} visible={modalOptions.visible} />
      <img width={34} src={require("../images/logo.png")} alt="" />
      <button onClick={() => setModalOptions({ visible: true })} className='rounded-md p-3 text-[white] bg-[#4ca14c] font-medium font-p' >Subscribe</button>
    </div>
  );

}

export default Header