import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import News from './components/News';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:news_id" element={<><Header/><News/></>} />
        <Route path="/" element={<><Header/><Home/></>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
