import { AiFillGithub } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { SiTwitter } from 'react-icons/si'
import { PiReadCvLogoFill } from 'react-icons/pi'
import { useEffect, useState } from 'react'
import axios from 'axios'

const Home = () => {

  const [newses, setNewses] = useState([])

  const getNewses = async () => {
    await axios.get("https://albedim.pythonanywhere.com/api/v1/news/get")
      .then(response => {
        setNewses(response.data)
      })
      .catch(error => { })
  }

  useEffect(() => {
    getNewses()
  },[])

  const navigate = useNavigate()

  function getTimeAgo(date) {
    const currentDate = new Date();
    date.setHours(date.getHours() - 2)
    const timeElapsed = currentDate.getTime() - date.getTime();
    const millisecondsPerMinute = 60 * 1000;
    const millisecondsPerHour = millisecondsPerMinute * 60;
    const millisecondsPerDay = millisecondsPerHour * 24;
    const millisecondsPerWeek = millisecondsPerDay * 7;
    const millisecondsPerYear = millisecondsPerDay * 365;
  
    if (timeElapsed < millisecondsPerMinute) {
      const seconds = Math.floor(timeElapsed / 1000);
      return seconds + (seconds === 1 ? ' second ago' : ' seconds ago');
    } else if (timeElapsed < millisecondsPerHour) {
      const minutes = Math.floor(timeElapsed / millisecondsPerMinute);
      return minutes + (minutes === 1 ? ' minute ago' : ' minutes ago');
    } else if (timeElapsed < millisecondsPerDay) {
      const hours = Math.floor(timeElapsed / millisecondsPerHour);
      return hours + (hours === 1 ? ' hour ago' : ' hours ago');
    } else if (timeElapsed < millisecondsPerWeek) {
      const days = Math.floor(timeElapsed / millisecondsPerDay);
      return days + (days === 1 ? ' day ago' : ' days ago');
    } else if (timeElapsed < millisecondsPerYear) {
      const weeks = Math.floor(timeElapsed / millisecondsPerWeek);
      return weeks + (weeks === 1 ? ' week ago' : ' weeks ago');
    } else {
      const years = Math.floor(timeElapsed / millisecondsPerYear);
      return years + (years === 1 ? ' year ago' : ' years ago');
    }
  }

  return (
    <div className="justify-around items-center flex w-screen">
      <div>
        <div className='p-14'>
          <div className='p-4'>
            <h2 className={'mt-2 text-3xl font-semibold font-p'}>Authors</h2>
          </div>
          <div className="p-4 mt-4">
            <div style={{ maxWidth: 640 }} className="rounded-xl items-center flex-block p-8 shadow-lg">
              <div className='justify-around flex'><img className='logo' style={{ height: 154, width: 194, objectFit: 'cover' }} src={require("../images/logo.png")} alt="" /></div>
              <div className="p-4">
                <h2 className={'mt-2 text-3xl font-semibold font-p'}>Alberto Di Maio</h2>
                <h2 className={'mt-2 text-lg font-regular font-p'}>Hey there! Subscribe to my newsletter and let's talk about anything!</h2>
                <div className='mt-4 flex'>
                  <div className='pr-4'><a target='_blank' href="https://twitter.com/alberto_dimaio"><SiTwitter size={28} color='gray' /></a></div>
                  <div className='pr-4'><a target='_blank' href="https://github.com/albedim"><AiFillGithub size={28} color='gray' /></a></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='p-14'>
          <div className='p-4'>
            <h2 className={'mt-2 text-3xl font-semibold font-p'}>News</h2>
          </div>
          <div className="p-4 mt-4">
            {
              newses.map(news => (
                <div className='p-4'>
                  <div style={{ maxWidth: 640 }} className="justify-between flex p-8 rounded-xl shadow-lg">
                    <div>
                      <h2 className={'mt-2 text-3xl font-semibold font-p'}>{news.title}</h2>
                      <h2 className={'mt-2 text-lg font-regular font-p'}>{news.description}</h2>
                      <h2 className={'text-[gray] mt-4 text-lg font-bold font-p'}>{getTimeAgo(new Date(news.created_on))}</h2>
                    </div>
                    <div className='items-center justify-around flex'>
                      <PiReadCvLogoFill onClick={() => navigate("/" + news.news_id)} size={34} color='#4ca14c' />
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );

}

export default Home