import { AiFillGithub } from 'react-icons/ai'
import { SiTwitter } from 'react-icons/si'
import { PiReadCvLogoFill } from 'react-icons/pi'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import React, { useEffect, useState } from 'react'

const News = () => {

  const [content, setContent] = useState([])
  const newsId = useParams().news_id

  const getNewses = async () => {
    await axios.get("https://albedim.pythonanywhere.com/api/v1/news/get/" + newsId)
      .then(response => {
        setContent(response.data.param)
        console.log(content)
      })
      .catch(error => { })
  }

  useEffect(() => {
    getNewses()
  }, [])

  const renderHTML = (rawHTML) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div className="justify-around items-center flex w-screen">
      <div>
        <div className="p-8 mt-4">
          <div className="w-80 rounded-xl items-center flex p-4 shadow-lg">
            <div className='items-center justify-around flex'><img style={{ height: 54, width: 54, objectFit: 'cover' }} src={require("../images/logo.png")} alt="" /></div>
            <div className="p-4">
              <h2 className={'text-2xl font-semibold font-p'}>Alberto Di Maio</h2>
              <div className='mt-1 flex'>
                <div className='pr-4'><a target='_blank' href="https://twitter.com/alberto_dimaio"><SiTwitter size={21} color='gray' /></a></div>
                <div className='pr-4'><a target='_blank' href="https://github.com/albedim"><AiFillGithub size={21} color='gray' /></a></div>
              </div>
            </div>
          </div>
        </div>
        <div className='mt-14'>
          {
            content.map(c => (
              <>
                <div className='p-8'>
                  <div>
                    <h2 className={'mt-2 text-4xl font-semibold font-p'}>{c.title}</h2>
                    <h2 id='a' style={{ wordWrap: 'break-word', whiteSpace: 'pre-line', maxWidth: 640 }} className={'mt-2 text-lg font-regular font-p'}>{renderHTML(c.content)}</h2>
                  </div>
                </div>
                {
                  c.children.length > 0 ? (
                    <div className='p-8'>
                      {c.children.map(child => (
                        <div className='pt-1 flex'>
                          <div className='pt-4 pr-7'><div style={{ width: 2, height: '100%' }} className='bg-[gray]'></div></div>
                          <div>
                            <h2 className={'mt-2 text-3xl font-semibold font-p'}>{child.title}</h2>
                            <h2 style={{ wordWrap: 'break-word', whiteSpace: 'pre-line', maxWidth: 640 }} className={'mt-2 text-lg font-regular font-p'}>{renderHTML(child.content)}</h2>
                          </div>
                        </div>
                      ))}
                      <div className='h-10' ></div>
                    </div>
                  ) : (
                    <></>
                  )
                }
              </>
            ))
          }
        </div>
      </div>
    </div>
  );

}

export default News