import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { RiCloseFill } from "react-icons/ri";
import "./Modal.css";
import axios from "axios";


const Modal = ({ visible, onClose }) => {

  const [userSchema, setUserSchema] = useState({
    first_name: '',
    email: ''
  })

  const [error, setError] = useState("")
  const [response, setResponse] = useState("")

  const add = async () => {
    await axios.post("https://albedim.pythonanywhere.com/api/v1/user/add", userSchema)
      .then(response => {
        setResponse(response.data.param)
        setError("")
      })
      .catch(error => { setResponse(""); setError(error.response.data.error) })
  }

  const isUserSchemaValid = userSchema.first_name != "" && userSchema.email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)

  const handleUserSchema = (e) => {
    const newUserSchema = { ...userSchema }
    newUserSchema[e.target.name] = e.target.value
    setUserSchema(newUserSchema)
  }

  return (
    <>
      {
        visible ? (
          <div className="modal" onClick={onClose}>
            <div className="modal-wrapper p-4">
              <div style={{ borderRadius: 8 }} className="modal-content" onClick={e => e.stopPropagation()}>
                <div className="p-4 items-center justify-between flex">
                  <div></div>
                  <div><RiCloseFill className="cursor-pointer" onClick={onClose} color="gray" size={24} /></div>
                </div>
                <div className="p-8">
                  <div className='pb-3'>
                    <h2 style={{ fontSize: 16.4 }} className="pb-2 font-medium font-noto" >First Name</h2>
                    <input name='first_name' onChange={(e) => handleUserSchema(e)} value={userSchema.first_name} style={{ fontSize: 14 }} className="font-noto rounded-xl bg-opacity-30 bg-[#cacaca] pb-3 pt-3 pr-3 pl-3" type="text" />
                  </div>
                  <div className='pb-1'>
                    <h2 style={{ fontSize: 16.4 }} className="pb-2 font-medium font-noto" >E-mail</h2>
                    <input name='email' onChange={(e) => handleUserSchema(e)} value={userSchema.email} style={{ fontSize: 14 }} className="font-noto rounded-xl bg-opacity-30 bg-[#cacaca] pb-3 pt-3 pr-3 pl-3" type="text" />
                  </div>
                  <div>
                    <h2 style={{ fontSize: 14.4 }} className="text-[green] pb-2 font-medium font-noto" >{response}</h2>
                    <h2 style={{ fontSize: 14.4 }} className="text-[red] pb-2 font-medium font-noto" >{error}</h2>
                  </div>
                  <div className="pt-4">
                    <button disabled={!isUserSchemaValid} onClick={() => add()} className='rounded-md p-3 text-[white] bg-[#4ca14c] font-medium font-p' >Subscribe</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )
      }
    </>
  );
};

export default Modal;
